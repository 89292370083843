@import url('https://use.typekit.net/uhc7ufj.css');

:root {
  /* Font Families */
  --font-roboto: 'Roboto', sans-serif;
  --font-lato: 'Lato', sans-serif;
  --font-artifex: 'Artifex', sans-serif;
  --font-DINNextLTPro-MediumCond: 'DINNextLTPro-MediumCond';
  --font-DINNextLTPro-Regular: 'DINNextLTPro-Regular';
  --font-Moche-Bold: 'Moche-Bold';
  --font-Moderat-Bold: 'Moderat-Bold';
  --font-Moderat-Mono: 'Moderat-Mono';
  --font-Moderat-Medium: 'Moderat-Medium';
  --font-NHaasGroteskDSPro-45Lt: 'NHaasGroteskDSPro-45Lt', sans-serif;
  --font-NHaasGroteskDSPro-65Md: 'NHaasGroteskDSPro-65Md', sans-serif;
  --font-NHaasGroteskDSPro-55Rg: 'NHaasGroteskDSPro-55Rg', sans-serif;
  --font-PPMori: 'PPMori', sans-serif;

  /* Font Weights */
  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;

  --color-white: #ffffff;
  --color-brand: #5B765F;
  --color-brandDark: #183f31;
  --color-brandLight: #f2eeeb;
  --color-gray3: #222222;
  --color-gray7: #80807F;
  --color-gray9: #ABA293;
  --color-gray10: #787878;
  --color-highlighter: #e1f88f;
  --color-bundleColor: #FDCC2F;
  --color-everscoreColor: #F95738;
  --color-flowerBrandConst: #3F5DA7;
  --color-flowerBrandBright: #DCB7C9;

  /* Global Measurements */
  --button-border-radius: 8px;

  /* TRANSITION */
  --global-transition: .3s ease;
  --button-transition: .1s all ease-in-out;
}
