/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  line-height: 1;
  vertical-align: baseline;
  border: 0;
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

input,
textarea,
select {
  font-family: var(--font-lato);
}

ol, ul, li {
  margin: 0;
  padding:0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
  list-style: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}

select {
  font-family: var(--font-lato);
  line-height: 1;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  font-family: var(--font-lato);
  color: #222;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  background-color: var(--color-white);
  width: 100%;

  &.no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  &.has-scroll {
    overflow: visible;
  }
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  text-align: left;
  color: inherit;
  background: transparent;
  border: none;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}

.has-blur > * {
  filter: blur(2px);
}

main {
  height: 100%;
}
.loading-hide-page{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999999999;
}
.d-flex-container{
  display: flex;
}
a.d-flex-container{
  text-decoration: none;
}