:root {
  --type-scale-12: .75rem;
  --type-scale-13: .8125rem;
  --type-scale-14: .875rem;
  --type-scale-16: 1rem;
  --type-scale-18: 1.125rem;
  --type-scale-20: 1.25rem;
  --type-scale-22: 1.375rem;
  --type-scale-24: 1.5rem;
  --type-scale-26: 1.625rem;
  --type-scale-28: 1.75rem;
  --type-scale-30: 1.875rem;
  --type-scale-32: 2rem;
  --type-scale-36: 2.25rem;
  --type-scale-42: 2.625rem;
  --type-scale-48: 3rem;
  --type-scale-56: 3.5rem;
  --type-scale-74: 4.625rem;
}

// TODO: Add Typography Specs Below

.t-display {
  font-family: var(--font-artifex);
  font-weight: var(--weight-bold);
  letter-spacing: 1px;
  line-height: 1.2;

  @media (max-width: 799px) {
    font-size: var(--type-scale-28);
  }
  @media (min-width: 800px) {
    font-size: var(--type-scale-36);
  }
}

.t-sub-display {
  @media (max-width: 559px) {
    font-size: var(--type-scale-14);
    line-height: 1.57;
  }
  @media (min-width: 560px) {
    font-size: var(--type-scale-20);
    line-height: 1.6;
  }
}

.t-promo-display {
  line-height: 1.2;
  font-size: var(--type-scale-16);
}

.t-body-medium {
  font-size: var(--type-scale-18);
  line-height: 1.5;
}

.t-brand-eyebrow {
  line-height: 1.5;

  @media (max-width: 799px) {
    font-size: var(--type-scale-18);
  }
  @media (min-width: 800px) {
    font-size: var(--type-scale-20);
  }
}

.t-brand-copy {
  line-height: 1.2;
  
  @media (max-width: 799px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 800px) {
    font-size: var(--type-scale-18);
  }
}

.t-body {
  font-size: var(--type-scale-16);
  line-height: 1.2;
}

.t-body-flower {
  font-family: var(--font-Moderat-Mono);
  @media (max-width: 859px) {
    font-size: var(--type-scale-12);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-14);
  }
}

.t-body-everscore {
   font-family: var(--font-NHaasGroteskDSPro-45Lt);
  @media (max-width: 859px) {
    font-size: var(--type-scale-12);
  }
  @media (min-width: 860px) {
    line-height: 16.8px;
    font-size: var(--type-scale-14);
  }
}

.t-section-sub-heading-flower {
  font-size: var(--type-scale-16);
  line-height: 1.2;
  font-family: var(--font-Moderat-Medium);
  @media (max-width: 799px) {
    font-size: var(--type-scale-12);
  }
}

.t-body-small {
  font-size: var(--type-scale-14);
  line-height: 1.2;
}

.t-body-small--bold {
  font-weight: var(--weight-bold);
}

.t-section-heading-small {
  font-size: var(--type-scale-14);
  font-weight: var(--weight-bold);
}

.t-section-heading {
  font-size: var(--type-scale-24);
  font-weight: var(--weight-bold);
  font-family: var(--font-artifex);

  @media (max-width: 559px) {
    line-height: 1.17;
  }
  @media (min-width: 560px) {
    line-height: 1.33;
  }
}

.t-section-heading-flower {
  font-size: var(--type-scale-26);
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 559px) {
    line-height: 1.17;
    font-size: var(--type-scale-18);
  }
  @media (min-width: 560px) {
    line-height: 1.33;
  }
}

.t-section-subheading {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-18);
}

.t-aside-heading {
  font-size: var(--type-scale-16);
  font-weight: var(--weight-bold);
  font-family: var(--font-artifex);
}

.t-shelf-heading {
  font-weight: var(--weight-bold);
  font-family: var(--font-artifex);
  
  @media (max-width: 859px) {
    line-height: 1.17;
    font-size: var(--type-scale-18);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-24);
  }
}

.t-shelf-heading-flower {
  font-weight: var(--weight-bold);
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 859px) {
    line-height: 1.17;
    font-size: var(--type-scale-22);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-24);
  }
}

.t-shelf-heading-multishop {
  font-weight: var(--weight-bold);
  font-family: var(--font-NHaasGroteskDSPro-65Md);

  @media (max-width: 859px) {
    line-height: 1.17;
    font-size: var(--type-scale-22);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-24);
  }
}

.t-shelf-heading-bundle {
  font-weight: var(--weight-bold);
  font-family: var(--font-DINNextLTPro-Regular);

  @media (max-width: 859px) {
    line-height: 1.17;
    font-size: var(--type-scale-22);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-24);
  }
}

.t-shop-flower {
  font-weight: var(--weight-bold);
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 859px) {
    line-height: 1.17;
    font-size: var(--type-scale-24);
  }
  @media (min-width: 860px) {
    line-height: 1.33;
    font-size: var(--type-scale-26);
  }
}

.t-shelf-link {
  letter-spacing: .5px;
  text-decoration: none;
  transition: var(--button-transition);

  @media (max-width: 859px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-20);
  }
}

.t-shelf-link-flower {
  letter-spacing: .5px;
  text-decoration: none;
  transition: var(--button-transition);
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-shop-link-everscore {
  letter-spacing: .5px;
  text-decoration: none;
  transition: var(--button-transition);
  font-family: var(--font-NHaasGroteskDSPro-55Rg);
  line-height: 36px;
  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-shop-all-everscore {
  font-size: var(--type-scale-26);
  font-weight: bold;
  line-height: 31.2px;
  // font-family: var(--font-Moderat-Bold);
}

.t-shelf-link-mono-flower {
  letter-spacing: .5px;
  text-decoration: none;
  transition: var(--button-transition);
  font-family: var(--font-Moderat-Mono);

  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-shelf-link-mono-everscore {
  letter-spacing: .5px;
  text-decoration: none;
  transition: var(--button-transition);
  font-family: var(--font-NHaasGroteskDSPro-55Rg);

  @media (max-width: 859px) {
    font-size: var(--type-scale-17);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-shelf-link-light-flower {
  font-family: var(--font-Moderat-Medium);

  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-shelf-link-bold-flower {
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-18);
  }
}

.t-paragraph-flower {
  font-family: var(--font-Moderat-Medium);

  @media (max-width: 859px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-16);
  }
}

.t-paragraph-everscore {
  font-family: var(--font-NHaasGroteskDSPro-55Rg);

  @media (max-width: 859px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-16);
  }
}

.t-lineage-flower {
  font-family: var(--font-Moderat-Medium);

  @media (max-width: 859px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-16);
  }
}

.t-lineage-everscore {
  font-family: var(--font-NHaasGroteskDSPro-45Lt);

  @media (max-width: 859px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-16);
  }
}

.t-copyright-everscore {
  font-family: var(--font-NHaasGroteskDSPro-45Lt);

  @media (max-width: 859px) {
    font-size: var(--type-scale-12);
    line-height: 14.4px;
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-14);
    line-height: 16.8px;
  }
}

.t-description-flower{
  font-size: var(--type-scale-16);
  font-family: var(--font-Moderat-Medium);
  font-weight: var(--weight-bold);
}

.t-description-flower-light{
  font-size: var(--type-scale-16);
  font-family: var(--font-Moderat-Medium);
}

.t-section-title {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-24);
}

.t-eyebrow {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-12);
}

.t-eyebrow-flower {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-13);
  font-family: var(--font-Moderat-Medium);
}

.t-eyebrow-everscore {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-12);
  font-family: var(--font-NHaasGroteskDSPro-65Md);
}

.t-eyebrow-bundle {
  font-weight: var(--weight-bold);
  font-size: var(--type-scale-12);
  font-family: var(--font-DINNextLTPro-Regular);
}

.t-list-copy {
  font-size: var(--type-scale-14);
  line-height: 1.3;
}

.t-footer-heading,
.t-card-heading {
  font-size: var(--type-scale-16);
  font-weight: var(--weight-bold);
}
.t-copy-heading {
  font-size: var(--type-scale-22);
  font-weight: var(--weight-bold);
  font-family: var(--font-artifex);
}

.t-card-sub-heading {
  font-size: var(--type-scale-16);
  font-weight: var(--weight-bold);
}

.t-card-copy {
  font-size: var(--type-scale-12);
  font-weight: var(--weight-bold);
}

.t-list-footnote {
  font-size: var(--type-scale-12);
  line-height: 1.5;
}

.t-form-label {
  font-size: var(--type-scale-18);
  line-height: 1.22;
}

.t-form-input {
  font-size: var(--type-scale-16);
}

.t-collapse {
  font-size: var(--type-scale-16);
  line-height: 1.22;
}

.t-font-artifex {
  font-family: var(--font-artifex);
  font-weight: var(--weight-bold);
}

.t-font-lato-bold {
  font-family: var(--font-lato);
  font-weight: var(--weight-bold);
}

.t-font-lato-regular {
  font-family: var(--font-lato);
  font-weight: var(--weight-regular);
}

.t-font-DINNextLTPro-MediumCond {
  font-family: var(--font-DINNextLTPro-MediumCond);
}

.t-font-DINNextLTPro-Regular {
  font-family: var(--font-DINNextLTPro-Regular)!important;
}

.t-font-Moderat-Mono-Regular {
  font-family: var(--font-Moderat-Mono);
}

.t-font-Moderat-Medium {
  font-family: var(--font-Moderat-Medium);
}

.t-font-Moderat-Bold {
  font-family: var(--font-Moderat-Bold);
}

.t-title-large {
  font-size: var(--type-scale-36);
  line-height: 1.22;
}

.t-form-button {
  font-size: var(--type-scale-14);
  font-weight: var(--weight-bold);
  letter-spacing: .5px;
  text-decoration: none;
}

.t-nav-link {
  font-size: var(--type-scale-14);
  font-weight: var(--weight-bold);
  line-height: 1.2;
  text-decoration: none;
}

.t-header-link {
  font-size: var(--type-scale-18);
  font-weight: var(--weight-bold);
  text-decoration: none;
  letter-spacing: 1px;
}

.t-title-huge {
  font-size: var(--type-scale-74);
  font-weight: var(--weight-bold);
  @media (max-width: 859px) {
    font-size: var(--type-scale-36);
  }
  @media (min-width: 860px) {
    font-size: var(--type-scale-74);
  }
}

.t-title-large {
  font-size: var(--type-scale-36);
  line-height: 1.22;
}

.t-title-big {
  font-size: var(--type-scale-36);
}

.t-title-medium {
  font-size: var(--type-scale-28);
  @media (max-width: 859px) {
    font-size: var(--type-scale-16);
  }
}

.t-title-mid {
  font-size: var(--type-scale-24);
}

.t-title {
  font-size: var(--type-scale-16);
  line-height: 1.56;
}

.t-title-small {
  font-size: var(--type-scale-16);
}

.t-subtitle {
  font-size: var(--type-scale-16);
  line-height: 1.56;
}

.t-sub-title-mid {
  font-size: var(--type-scale-48);
  @media (max-width: 859px) {
    font-size: var(--type-scale-18);
  }
  @media (min-width: 860px) {
    font-size: 49px;
  }
}

.t-sub-title-mid-2 {
  font-size: var(--type-scale-48);
  @media (max-width: 859px) {
    font-size: var(--type-scale-24);
  }
  @media (min-width: 860px) {
    font-size: 49px;
  }
}

.t-subtitle-large {
  font-family: var(--font-artifex);
  font-weight: var(--weight-bold);
  letter-spacing: 1px;
  line-height: 1.56;
  
  @media (max-width: 559px) {
    font-size: var(--type-scale-20);
  }
  @media (min-width: 560px) {
    font-size: var(--type-scale-24);
  }
}

.t-blog-title {
  font-weight: var(--weight-bold);
  font-family: var(--font-artifex);
  
  @media (max-width: 671px) {
    font-size: var(--type-scale-22);
  }
  @media (min-width: 672px) {
    font-size: var(--type-scale-28);
  }
}

.t-blog-eyebrow {
  font-weight: var(--weight-bold);
  
  @media (max-width: 671px) {
    font-size: var(--type-scale-14);
  }
  @media (min-width: 672px) {
    font-size: var(--type-scale-20);
  }
}

.t-seo-copy {
  font-size: var(--type-scale-28);
  @media (max-width: 859px) {
    font-size: 15px;
  }
}

.t-footnote {
  @media (max-width: 559px) {
    font-size: var(--type-scale-12);
    line-height: 1.22;
  }
  @media (min-width: 560px) {
    font-size: var(--type-scale-14);
    line-height: 1.29;
  }
}

.t-footer {
  text-decoration: none;
  font-size: var(--type-scale-18);
  font-weight: var(--weight-bold);;
  line-height: 1.19;
  letter-spacing: 1px;
}

.t-footer-flower {
  text-decoration: none;
  font-weight: var(--weight-bold);;
  line-height: 1.5;
  font-family: var(--font-Moderat-Bold);

  @media (max-width: 799px) {
    font-size: var(--type-scale-18);
  }
  @media (min-width: 800px) {
    font-size: var(--type-scale-20);
  }
}

.t-footer-everscore {
  text-decoration: none;
  font-weight: var(--weight-bold);;
  line-height: 24px;
  font-family: var(--font-NHaasGroteskDSPro-65Md);

  @media (max-width: 799px) {
    font-size: var(--type-scale-16);
    line-height: 21.6px;
  }
  @media (min-width: 800px) {
    font-size: var(--type-scale-18);
  }
}

.t-footer-link-everscore {
  text-decoration: none;
  font-weight: var(--weight-bold);
  font-family: var(--font-NHaasGroteskDSPro-65Md);
  font-size: var(--type-scale-18);
  line-height: 21.6px;
}

/* Edge case classes for font weight */
.t-bold {
  font-weight: var(--weight-bold);
}

.t-medium {
  font-weight: var(--weight-medium);
}

.t-link {
  font-weight: var(--weight-bold);
  line-height: 1.2;
}

.t-badge {
  font-size: var(--type-scale-14);
  font-weight: var(--weight-medium);
}

.t-sub-heading,
.t-card-list {
  line-height: 1.5;
  
  @media (max-width: 559px) {
    font-size: var(--type-scale-16);
  }
  @media (min-width: 560px) {
    font-size: var(--type-scale-18);
  }
}

.t-copy-medium {
  font-size: var(--type-scale-18);
  line-height: 21.6px;
  @media (max-width: 559px) {
    font-size: var(--type-scale-14);
    line-height: 16.8px;
  }
}

.t-hidden {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.t-font-NHaasGroteskDSPro-45Lt {
  font-family: var(--font-NHaasGroteskDSPro-45Lt);
};

.t-font-NHaasGroteskDSPro-55Rg {
  font-family: var(--font-NHaasGroteskDSPro-55Rg);
};

.t-font-NHaasGroteskDSPro-65Md {
  font-family: var(--font-NHaasGroteskDSPro-65Md);
};

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
}
