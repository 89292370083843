/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
       url('/fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/lato-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
       url('/fonts/lato-v16-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/lato-v16-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ArtifexHandCF-Regular - latin */
@font-face {
  font-family: 'Artifex';
  font-style: normal;
  font-weight: 700;
  src: local('Artifex Regular'), local('Artifex-Regular'),
       url('/fonts/ArtifexHandCF-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/ArtifexHandCF-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* DINNextLTPro-MediumCond */
@font-face {
  font-family: 'DINNextLTPro-MediumCond';
  font-style: normal;
  font-weight: 700;
  src: local('DINNextLTPro-MediumCond'), local('DINNextLTPro-MediumCond'),
       url('/fonts/DINNextLTPro-MediumCond.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/DINNextLTPro-MediumCond.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/DINNextLTPro-MediumCond.otf') format('otf');

}

/* DINNextLTPro-Regular  */
@font-face {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 700;
  src: local('DINNextLTPro-Regular'), local('DINNextLTPro-Regular'),
       url('/fonts/DINNextLTPro-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/DINNextLTPro-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/DINNextLTPro-Regular.otf') format('otf');
}

/* Moche-Bold  */
@font-face {
  font-family: 'Moche-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local('Moche-Bold'), local('Moche-Bold'),
       url('/fonts/Moche-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/Moche-Bold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/Moche-Bold.otf') format('otf');
}

/* Moderat-Bold  */
@font-face {
  font-family: 'Moderat-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Moderat-Bold'), local('Moderat-Bold'),
       url('/fonts/Moderat-Bold.eot') format('otf'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/Moderat-Bold.ttf') format('woff');
}

/* Moderat-Mono  */
@font-face {
  font-family: 'Moderat-Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Moderat-Mono'), local('Moderat-Mono'),
       url('/fonts/Moderat-Mono-Regular.ttf') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/Moderat-Mono-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/Moderat-Mono-Regular.otf') format('otf');
}

/* Moderat-Medium  */
@font-face {
  font-family: 'Moderat-Medium';
  font-style: normal;
  font-weight: 700;
  src: local('Moderat-Medium'), local('Moderat-Medium'),
       url('/fonts/Moderat-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/Moderat-Medium.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/fonts/Moderat-Medium.otf') format('otf');
}

/* PPMORI  */
@font-face {
  font-family: "PPMori";
  src: url("/fonts/ppmori/PPMori-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPMori";
  src: url("/fonts/ppmori/PPMori-RegularItalic.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PPMori";
  src: url("/fonts/ppmori/PPMori-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

//@font-face {
//  font-family: "DroidSerif";
//  src: url("DroidSerif-BoldItalic-webfont.ttf") format("truetype");
//  font-weight: bold;
//  font-style: italic;
//}